<template>
  <div>
    <center>
      <div class="box-large">
        <div
          class="flex text-left justify-start items-center"
          style="
            background-color: var(--secundary-blue-color);
            padding: 10px 20px;
          "
          :class="{
            'box-title-crypto': isCryptoCalc,
          }"
        >
          <div class="flex flex-col justify-center">
            <img width="60" style="min-width: 47px;" src="@/assets/logo.png" alt="" />
          </div>
          <div class="mx-10">
            <p
              class="text-white roboto-slab text-upper text-bold my-5"
              style="font-size: 20px; letter-spacing: 3px"
            >
              {{ isCryptoCalc ? "CONVERTIDOR DE CRIPTOMONEDAS" : title }}
            </p>
          </div>
        </div>
        <div class="p-5 flex justify-items-start"   :class="{
            'box-body-crypto': isCryptoCalc,
          }">
          <div class="mx-3 w-2/3" style="text-align: left">
            <label for="qty">Cantidad:</label><br />
            <input
              style="min-width: 200px; width: 100%; padding: 5px 15px"
              class="inputs"
              type="number"
              id="qty"
              v-model="qty"
            />
          </div>
          <div class="mx-3 w-1/3">
            <select
              v-model="currency"
              style="
                height: 40px;
                min-width: 70px;
                width: 100%;
                margin-top: 24px;
                background-color: rgb(9 18 47);
                color: white;
                padding: 5px;
              "
            >
              <option
                v-for="(cur, ind) in currencies"
                :value="cur.code"
                :key="ind"
              >
                {{ cur.name }}
              </option>
            </select>
          </div>
        </div>
        <!--      Center content-->
        <div
          :class="{
            'box-body-crypto': isCryptoCalc,
          }">
          <center>
            <easy-spinner class="p-5" v-if="entries.length == 0" :size="80" />
          </center>
        </div>
        <div class="p-5 grid grid-cols-1 sm:grid-cols-3 gap-4" style=""  :class="{
            'box-body-crypto': isCryptoCalc,
          }">
          <div
            v-for="entry in entries"
            :key="entry.key"
            class="price-box relative"
          >
            <span class="mt-2 inline-block align-middle">
              <b style="color: rgb(146 30 31)">{{
                parseFloat(exchangeValue(entry.value, entry.key) * qty).toFixed(
                  getComputedStyle(entry.key)
                )
              }}</b>
              {{ getCurrencyStyle(entry.key) }}</span
            >
          </div>
        </div>
        <div :class="{
            'box-body-crypto': isCryptoCalc,
          }">
          <!--        create radiogroup with v-model-->
          <div v-if="isCryptoCalc || isAllCalc">
            <input
              :value="true"
              type="radio"
              v-model="real"
              id="isreal"
            /><label for="isreal"> Unidades de cripto </label>
            <input
              :value="false"
              type="radio"
              v-model="real"
              id="notreal"
            /><label for="notreal"> USD en cripto </label>
          </div>
          <hr
            class="mx-3"
            style="border: 1px solid rgb(9 18 47); margin: 10px 30px"
          />
          <div>&nbsp;</div>
        </div>

        <div class="flex justify-items-around" style="padding-bottom: 30px"  :class="{
            'box-body-crypto': isCryptoCalc,
          }">
          <div class="change-type w-2/3">
            *Tipo de cambio del mercado informal a las
            <b>{{
              // show hour and minutes in am pm format
              new Date().toLocaleTimeString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })
            }}</b>
          </div>
          <div class="change-date w-1/3">
            <b>{{
              //show date in short format
              new Date().toLocaleDateString("es-ES", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
              })
            }}</b>
          </div>
        </div>
      </div>
    </center>
  </div>
</template>

<script>
import { ExchangeV2 } from "@/utils/currency";
import api from "@/axios/index";
// import apiEltoque from "@/axios/eltoque";

const isCUPCur = (curShort) => curShort === "CUP";

export default {
  name: "CalcAllValues",
  props: {
    title: String,
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.fetchCryptoMarket();
  },
  computed: {
    isCryptoCalc() {
      return this.$route.params.type == "crypto";
    },
    isAllCalc() {
      return this.$route.params.type == "all";
    },
  },
  watch: {
    // re-fetch whenever currentBranch changes
    currency: "fetchData",
    qty: "fetchData",
  },
  methods: {
    isCrypto(curShort) {
      return this.cryptos.indexOf(curShort) > -1;
    },
    getCurrencyStyle(currency) {
      let currencyStyle = currency;
      if (currencyStyle == "USDT_TRC20") {
        currencyStyle = "USDT";
      }
      if (currencyStyle == "ECU") {
        currencyStyle = "EUR";
      }
      return (this.isCrypto(currency) && this.real) || !this.isCrypto(currency) || isCUPCur(currency)
        ? currencyStyle
        : "USD/" + currencyStyle;
    },
    getComputedStyle(currency) {
      return this.isCrypto(currency) && this.real && !isCUPCur(currency) ? 6 : 2;
    },
    exchangeValue(value, currency) {
      if (
        (this.isCryptoCalc || this.isAllCalc) &&
        this.real &&
        this.isCrypto(currency) &&
        this.cryptoMarket.length > 0 &&
        this.cryptoMarket.find((item) => item.symbol == this.currency)
      ) {
        let TRXMultiplier = 1;
        if (this.currency == "TRX") {
          TRXMultiplier = this.cryptoMarket.find(
            (item) => item.symbol == "TRX"
          ).price;
        }
        return (
          value *
          TRXMultiplier *
          this.cryptoMarket.find((item) => item.symbol == this.currency).price
        );
      }
      return value;
    },
    async fetchCryptoMarket() {
      const me = this;

      return api.get("crypto-market-listings-latest").then((res) => {
        me.cryptoMarket = res.data.crypto_market_cap;
      });
    },
    async fetchData() {
      // fetch data
      try {
        const result = await this.exchange.convert({
          currency: this.currency,
          minimumCountMessages: 10,
          countDaysBefore: 15,
        });

        if (result[this.currency]) {
          this.entries = Object.entries(result[this.currency])
            .map(([key, value]) => ({ key, ...value }))
            .filter((x) => !isNaN(x.value) && (this.isAllCalc ||  (this.isCryptoCalc ?  this.isCrypto(x.key) : !(this.isCrypto(x.key)) || x.key == 'CUP'))            )
            .sort((x, y) => (this.sortedList.indexOf(y.key) - this.sortedList.indexOf(x.key)));
        } else {
          this.entries = [];
        }
        if (this.firsttime) {
          this.firsttime = false;
          this.currencies = [...this.currencies, ...this.entries.map((item) => ({code: item.key, name:this.getCurrencyStyle(item.key)}))];
        } else {
          // apiEltoque.post("/moneycalcs", {
          //   moneda: this.currency,
          //   cantidad: this.qty,
          //   fecha: new Date(),
          // });
        }

      } catch (e) {
        console.log(e);
      }
    },
  },
  data() {
    return {
      real: true,
      firsttime: true,
      currencies: [{ name: "CUP", code: "CUP" }],
      exchange: new ExchangeV2(),
      cryptoMarket: [],
      entries: [],
      currency: "CUP",
      qty: 100,
      cryptos: ["TRX", "BNB", "BTC", "USDT_TRC20", "CUP"],
      sortedList: [
        "TRX",
        "BNB",
        "BTC",
        "USDT_TRC20",
        "CHF",
        "CAD",
        "GBP",
        "ECU",
        "USD",
        "MLC",
        "CUP",
      ],
    };
  },
};
</script>

<style scoped>
.box-large {
  max-width: 800px;
  background-color: rgb(196 214 237);
  /*background-color: (9 48 17);*/
}
.price-box {
  height: 40px;
  border: 1px solid rgb(9 18 47);
  min-width: 25%;
  margin: 13px;
}
.hidebox {
  opacity: 0.3;
}
.inputs {
  background-color: transparent;
  height: 40px;
  border: 1px solid rgb(9 18 47);
}
.box-title {
  background-color: rgb(9 18 47);
  color: white;
  padding: 10px;
  font-size: 1.2em;
}
.box-title-crypto {
  background-color: #aa221c !important;
}
.box-body-crypto {
  background-color: rgba(236, 236, 236) !important;
}
</style>
